import React from 'react';
import '../index.css';

const Page = () => {
  return (
    <div>
      <h1>This website is under construction.</h1>
      <p>Sorry for the inconvenience.</p>
      <p>While waiting for the final release,</p>
      <p>Here are some useful links:</p>
      <ul>
        <li><a className='button' target="_blank" rel="noreferrer" href='https://cloud.stayclumsy.com'>Clumsy Cloud</a></li>
        <li><a className='button' target="_blank" rel="noreferrer" href='https://movie.stayclumsy.com'>Clumsy Movie</a></li>
        <li><a className='button' target="_blank" rel="noreferrer" href='https://findmovies.stayclumsy.com'>Movie Finder</a></li>
        <li><a className='button' target="_blank" rel="noreferrer" href='https://status.stayclumsy.com/'>Server status</a></li>
      </ul>
    </div>
  );
}

export default Page;