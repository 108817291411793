import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFoundPage from './components/404';
import HomePage from './components/Home';

import logo from './components/img/doglogo200.png';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <a href='/'><img id="img" src={logo} className='logo' alt="logo"/></a>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/*" element={<NotFoundPage/>} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;