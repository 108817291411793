import React from 'react';
import '../index.css'

const Page = () => {
    return (
        <div>
            <h1>404</h1>
            <h2>We can't find that page</h2>
            <p>We're fairly sure that page used to be here, but seems to have gone missing.</p>
            <a className='button' href="/" rel="noreferrer noopener">Home</a>
        </div>
    );
}

export default Page;